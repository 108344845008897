var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "update-log"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("组件更新日志")]), _c('Alert', {
    attrs: {
      "type": "warning",
      "show-icon": ""
    }
  }, [_vm._v("说明：大部分组件为包含真实数据接口的封装，方便大家直接使用")]), _c('Timeline', {
    staticClass: "timeline"
  }, [_c('TimelineItem', [_c('div', {
    staticClass: "version"
  }, [_vm._v("20220529 - v3.5.2")]), _c('ul', {
    attrs: {
      "type": "circle"
    }
  }, [_c('li', [_vm._v("wangEditor更新至v5.0.0，重构富文本编辑器组件，支持附件上传下载、@用户、暗黑模式")]), _c('li', [_vm._v("Monaco代码编辑器优化，height属性需带单位，新增focus聚焦方法")])])]), _c('TimelineItem', [_c('div', {
    staticClass: "version"
  }, [_vm._v("20220415 - v3.5.1")]), _c('ul', {
    attrs: {
      "type": "circle"
    }
  }, [_c('li', [_vm._v("修复素材中心组件分页Bug")])])]), _c('TimelineItem', [_c('div', {
    staticClass: "version"
  }, [_vm._v("20220222 - v3.5.0")]), _c('ul', {
    attrs: {
      "type": "circle"
    }
  }, [_c('li', [_vm._v("新增素材中心组件")]), _c('li', [_vm._v("图片上传输入框、图片上传缩略、富文本编辑器图支持素材中心选择上传")]), _c('li', [_vm._v("地图选点组件style属性更名为styles，mapbox更新至v2.7.0")]), _c('li', [_vm._v("重构优化monaco编辑器组件，支持更多属性配置，monaco更新至v0.32.1")]), _c('li', [_vm._v("wangEditor更新至v4.7.11")])])]), _c('TimelineItem', [_c('div', {
    staticClass: "version"
  }, [_vm._v("20210701 - v3.3.4")]), _c('ul', {
    attrs: {
      "type": "circle"
    }
  }, [_c('li', [_vm._v("新增中后台适用Widget物料组件")]), _c('li', [_vm._v("优化地图选点组件，支持仅预览模式")]), _c('li', [_vm._v("wangEditor更新至v4.7.5，默认表情包更新")])])]), _c('TimelineItem', [_c('div', {
    staticClass: "version"
  }, [_vm._v("20210305 - v3.3.3")]), _c('ul', {
    attrs: {
      "type": "circle"
    }
  }, [_c('li', [_vm._v("用户选择相关组件新增mask-style、class-name等属性以便自定义Drawer弹窗层级")]), _c('li', [_vm._v("修复文件上传单个为空判断BUG")]), _c('li', [_vm._v("wangEditor更新至v4.6.8")])])]), _c('TimelineItem', [_c('div', {
    staticClass: "version"
  }, [_vm._v("20210225 - v3.3.2")]), _c('ul', {
    attrs: {
      "type": "circle"
    }
  }, [_c('li', [_vm._v("新增基于Mapbox的地图选点组件")]), _c('li', [_vm._v("优化富文本编辑器组件编辑代码界面，支持全屏")]), _c('li', [_vm._v("代码编辑器组件新增layout方法自动重新布局编辑器宽度，新增showThemeChange是否显示主题切换开关属性配置")])])]), _c('TimelineItem', [_c('div', {
    staticClass: "version"
  }, [_vm._v("20210205 - v3.3.1")]), _c('ul', {
    attrs: {
      "type": "circle"
    }
  }, [_c('li', [_vm._v("修复部门树组件设置空值不生效BUG")]), _c('li', [_vm._v("修复文件上传/下载组件多文件上传回调BUG")]), _c('li', [_vm._v("减少用户抽屉选择宽度")]), _c('li', [_vm._v("wangEditor更新至4.6.6")])])]), _c('TimelineItem', [_c('div', {
    staticClass: "version"
  }, [_vm._v("20210128 - v3.3.0")]), _c('ul', {
    attrs: {
      "type": "circle"
    }
  }, [_c('li', [_vm._v(" 图标选择输入框新增Tab分类选择，新增自定义扩展图标、自定义输入或上传图片 ")]), _c('li', [_vm._v(" 新增扩展图标组件XIcon，兼容Ionicons、iconfont、图片链接URL、Base64 ")]), _c('li', [_vm._v("自定义列表选择说明文档新增数据结构说明")]), _c('li', [_vm._v("用户抽屉选择组件支持v-model用法，可搜索条件增加")]), _c('li', [_vm._v(" 用户搜索选择升级为用户搜索+抽屉选择，可配置是否显示抽屉选择按钮 ")]), _c('li', [_vm._v("图片上传缩略图组件默认关闭多图上传")]), _c('li', [_vm._v("文件上传/下载组件重构，支持选择多文件上传")]), _c('li', [_vm._v(" wangEditor更新至4.6.5，支持视频上传，新增z-index、height和图片视频上传开关属性配置 ")]), _c('li', [_vm._v(" 多个组件增加多个属性配置，让开发者自义定更多属性，如按钮大小颜色文字图标等 ")]), _c('li', [_vm._v(" 【废弃】即将废弃移除封装的Quill富文本组件，请使用wangEditor组件 ")])])])], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };